@import '../../../index.scss';

.reset-password {
  height: 100vh;
  overflow: hidden;
  background: $primary;
  display: flex;
  align-items: center;
  justify-content: center;

  &__text {
    width: 100%;
    color: #fff;
    text-align: center;
    margin-bottom: 2rem;
  }

  &__form {
    width: 300px;

    button {
      margin-top: 1rem;
    }
  }
}
