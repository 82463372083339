@import '../../../index.scss';

.flat-button {
  border: none;
  outline: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;
  color: $primary;
  font-size: 14px;

  &:focus {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  }

  &--danger {
    color: $danger;

    &:focus {
      box-shadow: 0 0 4px $danger;
    }
  }
}
