@import '../../../../index.scss';

.user-data {
  width: fit-content;
  font-size: 14px;
  color: $primary;
  font-weight: 300;
  width: 100%;

  &__row {
    display: flex;
    margin-bottom: 16px;

    > button {
      margin-left: 24px;
      background: #fff !important;
      border: 1px solid $primary;
      color: $primary;
      box-shadow: none;
    }
  }

  &__section-name {
    font-weight: bold;
  }

  &__value {
    display: flex;
    flex: 1 1 50%;
  }

  &__label {
    margin-right: 32px;
    flex: 0 1 150px;
  }

  &__update-btn {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $main-70;

    span:nth-of-type(2) {
      margin-left: 4px;
    }
  }
}
