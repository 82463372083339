@import '../../../../index.scss';

.content {
  max-width: 920px;
  padding: 24px 40px;

  h2 {
    color: $accent;
    font-weight: normal;
    margin-bottom: 24px;
  }
}

.form {
  width: fit-content;
  max-width: 100%;

  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 16px;
    font-size: 14px;

    &__label-container {
      margin-right: 32px;
    }

    &__label {
      margin-right: 4px;
      font-size: 14px;
      font-weight: 200;
    }
  }
}
