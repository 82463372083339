@import '../../../index.scss';

.nf {
  &__page {
    background: $primary;
    height: 100vh;
    width: 100%;
    position: relative;
  }

  &__bg-sign {
    display: block;
    font-size: 35vw;
    position: absolute;
    left: 5%;
    top: 30%;
    transform: translateY(-50%);
    line-height: 512px;
    background: linear-gradient(#00a86e4d 10%, #00a86e00 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    pointer-events: none;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 8px;
  }

  &__header {
    color: #fff;
    font-size: 24px;
    margin-bottom: 4px;
  }

  &__text {
    color: #fff;
    font-size: 14px;
    margin-bottom: 16px;
  }

  &__link {
    display: block;
    background: $accent;
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    padding: 8px;
    width: 320px;
    text-align: center;
    margin: 0 auto;
  }
}

.accent-text {
  color: $accent;
}
