@import '../../../index.scss';

.phone-field {
  border: 1px solid $secondary;
  border-radius: 5px;
  width: 320px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &--error {
    border-color: $danger;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__input {
    border: none;
    outline: none;
    padding: 4.5px 0;
    margin: 0 8px;
    width: 100%;
    font-size: 14px;
    color: $primary;
    width: 282px;

    &--hidden {
      display: none;
    }
  }

  &__clear-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background: none;
    width: 26px;
    height: 26px;
    cursor: pointer;
  }

  &__error {
    color: $danger;
  }
}
