@import '../../../index.scss';

.dadata-field {
  border: 1px solid $secondary;
  border-radius: 5px;
  width: 320px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &--error {
    border-color: $danger;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__input {
    border: none;
    outline: none;
    width: 100%;
    padding: 4.5px 0;
    margin: 0 4px 0 6px;
    font-size: 14px;
    color: $primary;
    width: 282px;
  }

  &__clear-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    width: 26px;
    height: 26px;
  }

  &__error {
    color: $danger;
  }
}

.react-dadata {
  &__input {
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    color: $primary;
    height: 17px;
    font-size: 14px;
  }

  &__suggestions {
    width: 480px;
    height: 240px !important;
    overflow-y: auto;
    background: #f4f4f5 !important;
    left: 0 !important;
    border-radius: 5px !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25) !important;
  }

  &__suggestion-note {
    display: none;
  }
  &__suggestion {
    color: $primary;
    margin: 4px 0;
    padding: 4px 8px;

    &:hover {
      background-color: #dfdfe3;
    }
    &--current {
      background-color: #dfdfe3;
      &:hover {
        background-color: #dfdfe3 !important;
      }
    }
  }

  &--highlighted {
    color: $secondary;
  }
}
