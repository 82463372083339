@import '../../../../../index.scss';

.search-bar {
  &__filters {
    display: flex;
    align-items: center;
  }

  &__search-date {
    display: flex;

    & > span {
      margin: 0 8px;
      line-height: 20px;
    }

    .date-picker__container,
    .date-picker {
      width: 110px;
    }
    .date-picker__input {
      width: 100px;
    }

    .calendar {
      left: 0;
    }
  }

  &__search-button {
    margin-left: 8px;
  }

  &__months {
    display: flex;
  }

  &__month {
    padding: 4px 8px;
    margin: 16px 8px 16px 0;
    border: 1px solid $secondary;
    border-radius: 5px;
    background: #fff;
    outline: none;
    cursor: pointer;
    color: $primary;

    &--selected {
      background: $secondary;
      color: #fff;
    }

    &:hover {
      background: $secondary;
      color: #fff;
    }
  }
}
