@import '../../../index.scss';

.search-row {
  height: 28px;
  width: 320px;
  display: flex;
  border: 1px solid $secondary;
  border-radius: 5px;

  &__icon {
    width: 32px;
    height: 28px;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;

    svg path {
      fill: $secondary;
    }
  }

  &__input {
    width: 286px;
    border: none;
    outline: none;
    background: none;
    font-size: 14px;
    color: $primary;
  }
}
