@import '../../../index.scss';

.rb {
  background: none;
  border: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  outline: none;
  position: relative;

  &:focus {
    > .rb__highlight {
      opacity: 0;
      border: none;
    }
    > .rb__tooltip {
      opacity: 0;
    }
  }

  &:focus:not(:focus-visible) {
    > .rb__highlight {
      opacity: 0;
      border: none;
    }
    > .rb__tooltip {
      opacity: 0;
    }
  }

  &:focus-visible {
    > .rb__highlight {
      opacity: 1;
      border: 1px solid $accent;
    }
    > .rb__tooltip {
      opacity: 1;
    }
  }

  &:hover {
    > .rb__highlight {
      opacity: 1;
    }
    > .rb__tooltip {
      opacity: 1;
    }
  }

  &__logo {
  }

  &__highlight {
    position: absolute;
    width: 32px;
    height: 32px;
    background: #ffffff4d;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  &__tooltip {
    position: absolute;
    background-color: #221c3c;
    color: #fff;
    left: -10px;
    bottom: -40px;
    padding: 0.2rem;
    opacity: 0;
    transition: all 0.3s ease-in-out 0.3s;
    pointer-events: none;
  }
}
