@import '../../../../../index.scss';

.statistic-table {
  width: 100%;
  color: $primary;
  font-size: 12px;
  border-collapse: collapse;

  &__container {
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #dfdfe3 #fff;
    height: calc(100vh - 345px);
  }

  &__head {
  }

  &__header {
    padding: 12px;
    text-align: start;
    font-weight: bold;
  }

  &__sortable-header {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    cursor: pointer;
    font-style: normal;
    font-weight: bold;
    color: $primary;
    font-size: 12px;

    & span:nth-of-type(2) {
      margin-left: 2px;
    }
  }

  &__data {
    padding: 12px;
    text-align: start;
    vertical-align: top;
    font-weight: 300;
    font-style: normal;
  }

  &__body {
    > tr {
      cursor: pointer;

      &:hover {
        background: #f4f4f5;
      }
    }
  }

  &__row {
    border-bottom: 1px solid $main-50;
  }

  &__status {
    display: flex;
    align-items: center;
    color: $primary;

    &-icon {
      margin-right: 4px;
      display: flex;
      align-items: center;
    }
    &-label {
      line-height: 17px;
      white-space: nowrap;
    }
  }
}
