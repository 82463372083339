@import '../../../../../index.scss';

.ls-summary {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  margin-bottom: 16px;

  &__total {
    grid-row: 1/2;
    grid-column: 1/2;
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;
  }

  &__details {
    grid-row: 2/3;
    grid-column: 1/2;
    display: flex;
    align-items: flex-end;
  }

  &__name {
    font-size: 14px;
    color: $primary;
    margin-bottom: 2px;
    color: $primary;
  }

  &__count {
    font-size: 20px;
    line-height: 30px;
    width: 30px;
    font-weight: bold;
    color: $main-70;
    text-align: center;
    margin-right: 8px;
    margin-left: 4px;

    &--done {
      color: $accent-70;
    }

    &--canceled {
      color: $danger-70;
    }
  }
}
