@import '../../../index.scss';

.alert {
  padding: 16px;
  border-radius: 5px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  animation: fadeIn 0.3s ease-in;
  min-width: 320px;
  max-width: 640px;

  &__header {
    font-size: 24px;
    margin-bottom: 16px;
    align-self: flex-start;

    &--danger {
      color: $danger;
    }

    &--success {
      color: $accent;
    }
  }

  &__message {
    font-size: 14px;
    margin-bottom: 16px;
    width: 100%;
    text-align: start;
  }

  &__background {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
