@import '../../../../index.scss';

.loading-bar {
  height: 2px;
  width: 100%;
}

.table-plug {
  font-size: 14px;
  color: $primary;
}
