@import '../../../../../index.scss';

.us-modal__background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.us-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;
  background: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 300;
  display: grid;
  color: $primary;
  min-width: 615px;

  & > .us-modal__row {
    grid-column: 1/3;
  }

  &__header {
    margin: 0 !important;
    font-size: 24px;
  }

  &__header-container {
    display: flex;
    align-items: flex-end;

    p {
      margin-left: 16px;
      font-size: 12px;
      line-height: 26px;
    }
  }

  
  &__user {
    grid-row: 2/3;
    grid-column: 2/3;
    width: 300px;

    & .ls-modal__label {
      margin-right: 32px;
      flex: 0 1 50px;
      white-space: nowrap;
    }
  }

  &__vehicle {
    grid-row: 2/3;
    grid-column: 1/2;
  }

  &__client-info {
    grid-row: 3/4;
    grid-column: 1/3;
  }

  &__status {
    grid-row: 2/3;
    grid-column: 2/3;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__section-name {
    font-weight: bold;
  }

  &__value {
    display: flex;
    flex: 1 1;
    min-width: 165px;
  }

  &__label {
    margin-right: 16px;
    flex: 0 1 270px;
    white-space: nowrap;
  }

  &__close {
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    margin-left: 32px;
  }
}
