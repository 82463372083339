@import '../../../../index.scss';

.content-container {
  width: 100%;
  overflow: auto;
}

.content {
  max-width: 1080px !important;
  padding: 24px 40px;

  h2 {
    color: $accent;
    font-weight: normal;
    margin-bottom: 24px;
  }
}
