@import '../../../index.scss';

.return-button {
  background: none;
  outline: none;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &__icon {
    margin-right: 4px;
  }
  &__label {
    color: $primary;
    font-size: 14px;
  }
}

.content-container {
  width: 100%;
  overflow: auto;
}

.content {
  max-width: 920px;
  padding: 24px 40px;

  h2 {
    color: $accent;
    font-weight: normal;
    margin-bottom: 24px;
  }
}
