@import '../../../index.scss';

.login-page {
  background-color: $primary;
  overflow: hidden;
  height: 100vh;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 100%;

  &__form-side {
    justify-self: center;
    align-self: center;
    padding: 0 16px;
  }
  &__logo-side {
    justify-self: center;
    align-self: center;
    position: relative;
    padding: 0 95px;
  }
  &__logo-container {
    position: absolute;
    width: 100%;
    height: 285px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: $primary;
  }
  &__logo {
    width: 360px;
    height: 95px;
  }
  &__form-title {
    width: 100%;
    color: $accent;
    margin-bottom: 24px;
    text-align: center;
  }
  &__login {
    width: 320px;
    height: 36px;
    border: 1px solid $accent;
    padding: 8px 0 8px 12px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  &__input {
    width: 100%;
    border: none;
    outline: none;
    background: none;
    color: #fff;
  }
  &__clear {
    display: flex;
    border: none;
    outline: none;
    background: none;
    margin: 0 8px;
    cursor: pointer;

    path {
      fill: $accent;
    }
  }
  &__submit-btn {
    width: 100%;
    height: 36px;
    background: $accent;
    outline: none;
    border: none;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  }
  &__pswd-actions {
    margin-top: 8px;
    .check__label {
      color: #fff;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__forgot-pswd {
    border: none;
    outline: none;
    background: none;
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(34, 28, 60) inset !important;
}
/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
}
input {
  filter: none;
}
