@import '../../../index.scss';

.header {
  height: 56px;
  background-color: $primary;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  padding: 12px 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__user {
    display: flex;
    flex-direction: column;
    color: #fff;

    &__login {
      font-size: 12px;
      line-height: 14px;
    }

    &__name {
      font-size: 14px;
      line-height: 14px;
    }
  }
}
