@import '../../../index.scss';

.file {
  height: 28px;
  width: 320px;

  &--error {
    margin-bottom: 16px;
  }

  &__hidden-input {
    display: none;
  }

  &__btn {
    padding: 4px 8px;
    border: none;
    outline: none;
    background: $secondary;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    border-radius: 5px;
  }

  &__value {
    padding-left: 8px;
    border-radius: 5px;
    background: #fff;
    border: 1px solid $secondary;
    max-width: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__file-name {
    width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 300;
  }

  &__delete-file-btn {
    width: 28px;
    height: 28px;
    cursor: pointer;
    border: none;
    background: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__error {
    color: $danger;
    display: inline-block;
    width: 100%;
  }
}
