@import '../../../index.scss';

.button {
  padding: 8px;
  border-radius: 5px;
  color: #fff;
  background: $secondary;
  font-size: 14px;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

  &:focus {
    box-shadow: 0 0 4px $secondary;
  }

  &--small {
    padding: 4px 8px;
  }
}
