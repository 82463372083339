@import '../../../index.scss';

.fp {
  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
  }

  &__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 24px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    animation: fadeIn 0.3s ease-out;
    border-radius: 5px;
  }

  &__title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    h2 {
      font-size: 24px;
      color: $accent;
      font-weight: 400;
    }

    button {
      border: none;
      background: none;
      outline: none;
      cursor: pointer;
    }
  }

  &__text {
    font-size: 14px;
    width: 100%;
    text-align: start;
    margin-bottom: 16px;
    width: 320px;
  }

  &__input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid $accent;
    font-size: 14px;
    outline: none;
    width: 320px;
  }

  &__submit {
    width: 100%;
    padding: 8px 12px;
    border: none;
    outline: none;
    background: $accent;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    width: 320px;
  }

  &__error {
    display: block;
    color: $danger;
    margin-bottom: 16px;
    max-width: 320px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
