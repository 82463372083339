.tooltip {
  position: relative;
  border: none;
  outline: none;
  background: none;
  cursor: help;
  height: 16px;
  width: 16px;

  &:hover {
    .tooltip__title {
      display: block;
    }
  }

  &__icon {
    display: flex;
  }

  &__title {
    position: absolute;
    padding: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    background: #f4f4f5;
    font-size: 14px;
    width: 160px;
    top: 22px;
    left: 0;
    transform: translateX(-10%);
    text-align: start;
    border-radius: 5px;
    display: none;
    z-index: 10;
  }
}
