@import '../../../../index.scss';

.calendar {
  position: absolute;
  width: 295px;
  height: 272px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  left: 25px;

  &--top {
    bottom: 34px;
  }

  &--bottom {
    top: 34px;
  }

  &--open {
    opacity: 1;
    pointer-events: auto;
  }

  &--close {
    opacity: 0;
    pointer-events: none;
  }

  &--with-time {
    width: 295px;
  }

  &--without-time {
    width: 248px;
  }

  &__date-board {
    padding: 12px 0 12px 12px;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 14px;
    color: $primary;
    height: 24px;
  }

  &__header-btn {
    border: none;
    outline: none;
    background: none;
    width: 24px;
    height: 24px;
    cursor: pointer;

    svg path {
      fill: $primary;
    }

    &--right {
      transform: rotate(180deg);
    }
  }

  &__current-month {
    font-size: 14px;
    color: $primary;
    animation: fadeIn 0.3s ease-in;
  }

  &__days {
    display: grid;
    grid-template-columns: repeat(7, auto);
  }

  &__day-of-week {
    color: $main-50;
    font-size: 14px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__day {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    color: $primary;
    font-size: 14px;
    border-radius: 5px;
    animation: fadeIn 0.3s ease-in;

    &:hover {
      background: #dfdfe3;
      color: $secondary;
    }

    &:disabled {
      pointer-events: none;
      color: $main-50;
    }

    &--selected {
      background: #dfdfe3;
      color: $secondary;
    }

    &--skipped {
      pointer-events: none;
    }
  }

  &__time-picker {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 4px 0;
    scrollbar-color: #dfdfe3 #fff;
    scrollbar-width: thin;
    border-left: 1px solid #dfdfe3;

    &__time {
      border: none;
      outline: none;
      background: none;
      font-size: 14px;
      padding: 4px 8px 4px 7px;
      cursor: pointer;

      &--selected {
        background: #dfdfe3;
        color: $secondary;
      }

      &:hover {
        background: #dfdfe3;
        color: $secondary;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
