@import '../../../../index.scss';

.drawer {
  height: calc(100vh - 56px);
  background: #f4f4f5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-right: 1px solid $primary;
  position: relative;
  transition: all 0.3s ease-in;
  display: flex;
  flex-direction: column;

  &--opened {
    width: 300px;
  }

  &--closed {
    width: 48px;
    background: #fff;
    border: none;
    padding: 0;
    box-shadow: none;
  }

  &:hover {
    .drawer__toggle-btn {
      opacity: 1;
    }
  }

  &__title {
    font-size: 14px;
    color: $primary;
    width: 205px;
    padding: 34px 14px;
    transition: all 0.2s ease-in;

    &--opened {
      opacity: 1;
      pointer-events: auto;
    }

    &--closed {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__toggle-btn {
    position: absolute;
    right: -12px;
    top: 32px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: none;
    outline: none;
    background: #f4f4f5;
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in;

    &--opened {
      opacity: 0;
      transform: rotate(0);
    }

    &--closed {
      transform: rotate(180deg);
      right: 8px;
    }

    &:hover,
    &:focus {
      background: $secondary;
      path {
        fill: #fff;
      }
    }
  }

  &__content {
    flex: 1;
    width: 100%;
    padding: 0 8px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-color: #dfdfe3 #f4f4f5;
    scrollbar-width: thin;
  }

  &__footer {
    padding: 43px 14px;
    font-size: 14px;
    transition: all 0.2s ease-in;

    p {
      color: $main-70;
    }

    a {
      text-decoration: none;
      color: $accent;

      &:focus {
        box-shadow: inset 0 0 0 1px $accent;
      }
    }

    &--opened {
      opacity: 1;
      pointer-events: auto;
    }

    &--closed {
      opacity: 0;
      pointer-events: none;
    }
  }
}
