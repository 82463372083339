@import '../../../../index.scss';

.um {
  position: absolute;
  width: 160px;
  background: #f4f4f5;
  border-radius: 5px;
  top: 32px;
  right: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;

  &__list {
    list-style: none;
  }

  &__list-divider {
    display: block;
    height: 2px;
    background: #dfdfe3;
  }

  &__list-item {
    margin: 4px 0;
    font-size: 14px;

    a {
      display: block;
      color: $primary;
      text-decoration: none;
      width: 100%;
      padding: 4px 8px;
    }

    button {
      color: $primary;
      border: none;
      outline: none;
      cursor: pointer;
      text-decoration: none;
      width: 100%;
      padding: 4px 8px;
      text-align: left;
      font-size: 14px;

      &:hover {
        background: #dfdfe3;
      }
    }

    &:hover {
      background: #dfdfe3;
    }
  }

  &__container {
    display: flex;
    margin-right: 10px;
    position: relative;
  }

  &__background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
}
