$primary: #221c3c;
$accent: #00a86e;
$secondary: #41b7b0;

$secondary-70: #7acdc8;

$danger: #dc3545;

$accent-70: #00a86db3;
$accent-40: #00a86d66;
$main-10: #221c3c1a;
$main-50: #221c3c80;
$main-70: #221c3cb3;
$main-80: #221c3ccc;
$danger-30: #dc35454d;
$danger-70: #dc3545b3;
