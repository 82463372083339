@import '../../../../index.scss';

.team {
  &__table {
    color: $primary;
    margin: 16px 0;
    font-size: 12px;
    font-weight: 300;
    border-collapse: collapse;

    &__row {
      border-bottom: 1px solid $main-50;
    }

    &__header {
      padding: 12px;
      text-align: start;
      font-weight: bold;
    }

    &__data {
      padding: 12px;
      text-align: start;
      vertical-align: top;
      font-weight: 300;
    }

    tbody > &__row:hover {
      background: #f4f4f5;
      cursor: pointer;
    }
  }

  &__plug {
    font-size: 14px;
    color: $primary;
  }
}
