@import '../../../../index.scss';

.hn {
  position: absolute;
  background: #f4f4f5;
  width: 320px;
  right: 0;
  top: 32px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  max-height: 500px;
  scrollbar-color: #dfdfe3 #f4f4f5;
  scrollbar-width: thin;
  overflow-y: scroll;
  z-index: 2;

  &__list {
    list-style: none;
  }

  &__list-item {
    padding: 4px 8px;
    margin: 4px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &:hover {
      background: #dfdfe3;
    }
  }

  &__delete-all {
    padding: 4px 8px;
    margin: 4px 0;
    display: flex;
    justify-content: flex-end;
    border-bottom: 2px solid #dfdfe3;
  }

  &__btn {
    border: none;
    outline: none;
    background: none;
    font-size: 14px;
    color: $primary;
    text-decoration: underline;
    cursor: pointer;
  }

  &__container {
    display: flex;
    margin-right: 10px;
    position: relative;
  }

  &__background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__no-notifications {
    padding: 4px 8px;
    margin: 4px 0;
    font-size: 14px;
  }

  &__notification-msg {
    font-size: 14px;
    color: $primary;
  }

  &__icon {
    &--empty {
      path {
        &:nth-of-type(3) {
          fill: #ffffff;
        }
      }
    }
    &--fill {
      path {
        &:nth-of-type(3) {
          fill: #00a86e;
        }
      }
    }
  }
}
