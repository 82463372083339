@import '../../../../../index.scss';

.leasing-subject {
  padding: 16px;
  border: 1px solid $primary;
  border-radius: 5px;
  margin-bottom: 16px;
  width: fit-content;
  max-width: 100%;
  animation: fadeIn 0.3s ease-in;

  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 16px;
    font-size: 14px;

    &:last-of-type {
      margin: 0;
    }

    &__files {
      width: 320px;
      display: flex;
      flex-wrap: wrap;

      > div {
        width: 100px;
        margin-right: 6px;
        margin-bottom: 6px;
      }

      .file--error {
        margin-bottom: 54px;
      }
    }

    &__label-container {
      margin-right: 32px;
    }

    &__label {
      margin-right: 4px;
      font-size: 14px;
    }
  }
}

.hidden-field {
  visibility: hidden;
  position: absolute;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
