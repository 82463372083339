@import '../../../index.scss';

.check {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: justify;
  cursor: pointer;

  &__box {
    background: url('../../../assets/checkbox/light/checkbox_uncheked.svg')
      no-repeat center center;
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }

  &__input {
    position: absolute;
    opacity: 0;

    &:checked + .check__box {
      background: url('../../../assets/checkbox/light/checkbox_checked.svg')
        no-repeat center center;
    }
    &:not(:checked):focus + .check__box {
      background: url('../../../assets/checkbox/light/checkbox_unchecked_focused.svg')
        no-repeat center center;
    }
    &:focus:checked + .check__box {
      background: url('../../../assets/checkbox/light/checkbox_checked_focused.svg')
        no-repeat center center;
    }

    &:disabled:checked + .check__box {
      background: url('../../../assets/checkbox/light/checkbox_checked_disabled.svg')
        no-repeat center center;
    }

    &:disabled + .check__box {
      background: url('../../../assets/checkbox/light/checkbox_unchecked_disabled.svg')
        no-repeat center center;
    }
  }

  &__label {
    font-size: 14px;
    color: $primary;
    margin-left: 20px;
  }

  &__error {
    color: $danger;
  }

  &--dark {
    .check__box {
      background: url('../../../assets/checkbox/dark/checkbox_uncheked.svg')
        no-repeat center center;
      position: absolute;
      width: 1rem;
      height: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }

    .check__input {
      position: absolute;
      opacity: 0;

      &:checked + .check__box {
        background: url('../../../assets/checkbox/dark/checkbox_checked.svg')
          no-repeat center center;
      }
      &:not(:checked):focus + .check__box {
        background: url('../../../assets/checkbox/dark/checkbox_unchecked_focused.svg')
          no-repeat center center;
      }
      &:focus:checked + .check__box {
        background: url('../../../assets/checkbox/dark/checkbox_checked_focused.svg')
          no-repeat center center;
      }

      &:disabled:checked + .check__box {
        background: url('../../../assets/checkbox/dark/checkbox_checked_disabled.svg')
          no-repeat center center;
      }

      &:disabled + .check__box {
        background: url('../../../assets/checkbox/dark/checkbox_unchecked_disabled.svg')
          no-repeat center center;
      }
    }
  }
}
