@import '../../../../../index.scss';

.ltm {
  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    left: 0;
    top: 0;
  }

  &__container {
    width: 500px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 24px;
    background: #fff;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 300;
  }

  &__header {
    margin: 0 !important;
    font-size: 24px;
    font-weight: 400;
    color: $accent;
    margin-right: 32px;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__section-name {
    font-weight: bold;
    color: $primary;
  }

  &__value {
    display: flex;
    color: $primary;
    width: 100%;
  }

  &__label {
    margin-right: 32px;
    white-space: nowrap;
    display: flex;
    flex: 0 1 50%;
    color: $primary;
  }

  &__section-name {
    font-weight: bold;
  }

  &__close {
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    margin-left: 32px;
  }
}
