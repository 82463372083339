@import '../../../../index.scss';

.drawer-link {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  text-decoration: none;
  max-width: 100%;
  min-width: 40px;
  height: 40px;
  transition: all 0.1s ease-in;
  outline: none;

  &:focus {
    box-shadow: inset 0px 0px 0px 1px $secondary;
  }

  &--active,
  &:hover {
    background: #dfdfe3;

    .drawer-link__icon {
      svg path {
        fill: $secondary;
      }
    }

    .drawer-link__name {
      color: $secondary;
    }
  }

  &__icon {
    display: flex;
    align-items: center;

    svg path {
      fill: $primary;
    }
  }

  &__name {
    margin-left: 16px;
    font-size: 14px;
    color: $primary;
    white-space: nowrap;
    flex: 1;
  }
}
